import { getContentfulField } from 'common/components';
const DATE_CHANGE_FEE = /^.*(DATE.*CHANGE.*FEE|CHANGE.*DATE.*FEE).*/;
const PARK_ACCESS_REGEX = /^(?=.*\bpark\b)(?=.*\bentry\b).*$/i;
const WRISTBANDS_REG = /^(print).*(wristband).*/i;

export const getCustomMetadata = async (venue, metaSublinkId) => {
  const fields = venue.content;

  if (fields?.subLinksMetadata) {
    const contentfulMetaSubLinks = getContentfulField(fields.subLinksMetadata);

    const metaSubLinks = contentfulMetaSubLinks.map((subLinkMetadata) => {
      const parsedMetadata = {
        id: getContentfulField(subLinkMetadata.fields.subPageIdentifier),
        metaTitle: getContentfulField(subLinkMetadata.fields.metaTitle),
        metaDescription: getContentfulField(
          subLinkMetadata.fields.metaDescription
        ),
      };
      return parsedMetadata;
    });

    const customMetadata = metaSubLinks.find((subLink) => {
      return subLink.id === metaSublinkId;
    });

    return customMetadata;
  }
};

export const isVenueOpenForDay = (day) => {
  const { closeHour, closeMinute = 0, isClosed } = day;

  // If venue is closed for the day
  if (isClosed) return false;

  // Real time date now to compare
  const localDate = new Date().toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
  const today = new Date(localDate);
  const hourNow = today.getHours();
  const minutesNow = today.getMinutes();

  // If real time is equal or less than closing time
  if (
    hourNow > closeHour ||
    (hourNow === closeHour && minutesNow >= closeMinute)
  ) {
    return false;
  } else return true;
};

export const isDateChangeFee = (productName) =>
  DATE_CHANGE_FEE.test(productName.toUpperCase());

export const isParkAccessPass = (productName) =>
  PARK_ACCESS_REGEX.test(productName.toUpperCase());

export const isWristbandProduct = (productName) =>
  WRISTBANDS_REG.test(productName);

export const isSSKProduct = (productName) =>
  productName.toUpperCase().includes('SSK');
