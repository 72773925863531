import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import {
  getContentfulField,
  getContentfulAssetUrlandAlt,
  DesktopOnly,
  MobileOnly,
} from 'common/components';
import {
  parseTextBlock,
  parseImageGallery,
  parseMenuBar,
  parsePricingCards,
  parseCollapsibleList,
} from 'public/helpers/contentful/parser';
import { useClassName } from 'common/hooks';
import {
  ContentfulTextBlock,
  ContentfulImageGallery,
  ContentFulCTABlock,
  ContentFulMenuBar,
  ContentFulPricingCards,
  ContentFulCollapsibleList,
  ContentfulModularForm,
} from '../ModularComponents';
import Breadcrumb from '../Breadcrumb';
import { Spacer } from '../Spacer';
import { PrivateEventsForm } from '../PrivateEvents';

import './landing-page.less';

const LANDING_COMPONENTS = {
  textBlock: 'textBlock',
  imageGallery: 'imageGallery',
  landingPage: 'landingPage',
  menuBar: 'menuBar',
  pricingCards: 'pricingCards',
  collapsibleList: 'collapsibleList',
  form: 'form',
};

const LandingPage = ({ object }) => {
  const className = useClassName('LandingPage');
  const components = getContentfulField(object.fields.components);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [links, setLinks] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBreadcrumbLinks();
  }, []);

  const getBreadcrumbLinks = () => {
    if (!object?.fields?.breadcrumb) return [];
    const breadcrumbValues = getContentfulField(object.fields.breadcrumb);
    const links = [];
    breadcrumbValues.forEach((breadcrumbValue) => {
      const key = breadcrumbValue?.fields?.key
        ? getContentfulField(breadcrumbValue.fields.key)
        : '';
      const value = breadcrumbValue?.fields?.value
        ? getContentfulField(breadcrumbValue.fields.value)
        : '';
      links.push({ url: value, title: key });
    });
    setLinks(links);
  };

  const getBackgroundColor = (component) => {
    if (!component?.fields) return;

    const { backgroundColor, backgroundSecondaryColor, backgroundImage } =
      component?.fields;

    if (backgroundImage) {
      const { assetUrl } = getContentfulAssetUrlandAlt(backgroundImage);
      const image = new Image();
      image.src = assetUrl;
      return `url(${image.src})`;
    }
    if (backgroundColor && backgroundSecondaryColor) {
      return `linear-gradient(180deg, ${getContentfulField(
        backgroundSecondaryColor
      )} 29.13%, ${getContentfulField(backgroundColor)} 90.94%)`;
    }
    if (backgroundColor) return getContentfulField(backgroundColor);
    return '';
  };

  const handleCategorySelected = (category) => setSelectedCategory(category);

  const shouldRender = (category) =>
    !category ||
    !selectedCategory ||
    (Boolean(category) &&
      category
        .split(',')
        .map((cat) => cat.trim().toUpperCase())
        .includes(selectedCategory.trim().toUpperCase()));

  const getTextBlock = (textBlockComponent, background) => {
    const textBlock = parseTextBlock(textBlockComponent);
    const { category, spacerSize, name } = textBlock;

    const innerBackground =
      ((Boolean(textBlock.slider) && textBlock.isHero) ||
        !textBlock.isHero ||
        textBlock.isCtaBlock) &&
      background;

    if (!shouldRender(category)) return null;

    const classes = ['text-block'];

    if (textBlock.isCtaBlock) classes.push('cta-block');
    if (innerBackground) classes.push('text-block-inner-bg');

    return (
      <div id={kebabCase(name)} className={className(classes)} key="text-block">
        {textBlock.isCtaBlock ? (
          <ContentFulCTABlock
            textBlock={textBlock}
            background={innerBackground}
          />
        ) : (
          <ContentfulTextBlock
            textBlock={textBlock}
            background={innerBackground}
          />
        )}
        {renderSpacer(spacerSize)}
      </div>
    );
  };

  const getImageGallery = (imageGalleryComponent) => {
    const imageGallery = parseImageGallery(imageGalleryComponent);
    const { category, spacerSize, name } = imageGallery;

    if (!shouldRender(category)) return null;

    const classes = [];

    if (imageGallery.isCarousel) classes.push('gallery-carousel');

    return (
      <div
        key="gallery"
        id={kebabCase(name)}
        className={className(['gallery', ...classes])}>
        <ContentfulImageGallery imageGallery={imageGallery} />
        {renderSpacer(spacerSize)}
      </div>
    );
  };

  const getLandingPage = (landingPageComponent) => (
    <LandingPage object={landingPageComponent} />
  );

  const getMenuBar = (menuBarComponent) => {
    const menuBar = parseMenuBar(menuBarComponent);
    const { spacerSize, name } = menuBar;
    return (
      <div id={kebabCase(name)} className={className('menu-bar')}>
        <ContentFulMenuBar
          menuBar={menuBar}
          handleCategorySelected={handleCategorySelected}
        />
        {renderSpacer(spacerSize)}
      </div>
    );
  };

  const getPricingCards = (pricingCardsComponent) => {
    const pricingCards = parsePricingCards(pricingCardsComponent);
    const { category, spacerSize, name } = pricingCards;
    if (!shouldRender(category)) return null;

    return (
      <div
        key="pricing-cards"
        id={kebabCase(name)}
        className={className('pricing-cards')}>
        <ContentFulPricingCards pricingCards={pricingCards} />
        {renderSpacer(spacerSize)}
      </div>
    );
  };

  const getCollapsibleList = (collapsibleListComponent) => {
    const collapsibleList = parseCollapsibleList(collapsibleListComponent);
    const { category, spacerSize, name } = collapsibleList;
    if (!shouldRender(category)) return null;
    return (
      <div
        key="collapsible-list"
        id={kebabCase(name)}
        className={className('collapsible-list')}>
        <ContentFulCollapsibleList collapsibleList={collapsibleList} />
        {renderSpacer(spacerSize)}
      </div>
    );
  };

  const getPrivateEventsForm = (formComponent, background) => {
    const fields = getContentfulField(formComponent.fields.fields);
    if (fields?.length) {
      return (
        <ContentfulModularForm
          id="private-events-form"
          form={formComponent}
          background={background}
        />
      );
    }

    return (
      <PrivateEventsForm
        id="private-events-form"
        form={formComponent}
        background={background}
      />
    );
  };

  const getComponentToRender = (component, background) => {
    switch (component.sys.contentType.sys.id) {
      case LANDING_COMPONENTS.textBlock:
        return getTextBlock(component, background);
      case LANDING_COMPONENTS.imageGallery:
        return getImageGallery(component);
      case LANDING_COMPONENTS.landingPage:
        return getLandingPage(component);
      case LANDING_COMPONENTS.menuBar:
        return getMenuBar(component);
      case LANDING_COMPONENTS.pricingCards:
        return getPricingCards(component);
      case LANDING_COMPONENTS.collapsibleList:
        return getCollapsibleList(component);
      case LANDING_COMPONENTS.form:
        return getPrivateEventsForm(component, background);
      default:
        return null;
    }
  };

  const renderSpacer = (spacerSize) => (
    <>
      <DesktopOnly>
        <Spacer size={spacerSize || 'm'} />
      </DesktopOnly>
      <MobileOnly>
        <Spacer size={spacerSize === 'xs' ? spacerSize : 's'} />
      </MobileOnly>
    </>
  );

  const background =
    object.fields.background && getContentfulField(object.fields.background);
  return (
    <div
      className={className('container')}
      {...(background && {
        style: {
          background,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
      })}>
      <div className={className('content')}>
        {links?.length > 0 && (
          <div className={className('bread-crumb-wrapper')}>
            <div className={className('bread-crumb-container')}>
              <Breadcrumb links={links} />
            </div>
          </div>
        )}
        {components.map((component, index) => {
          const background = getBackgroundColor(component);
          const componentToRender = getComponentToRender(component, background);
          if (!componentToRender) return null;
          return (
            componentToRender && (
              <div
                key={`component-${index}`}
                className={className('component')}
                style={{
                  background,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}>
                {componentToRender}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

LandingPage.propTypes = {
  object: PropTypes.object.isRequired,
};

export default LandingPage;
