import { action } from 'mobx';
import { request } from 'utils/api';
import BaseStore from './BaseStore';

export const MondayColumnNames = {
  firstName: 'lead_first_name',
  lastName: 'lead_last_name',
  name: 'item_name',
  phone: 'lead_phone',
  email: 'lead_email',
  groupType: 'status_mkk98rqm',
  venue: 'status_mkk9zrfh',
  numberOfPeople: 'numbers_mkk943w3',
  eventDate: 'event_date_mkk9sm82',
  message: 'contact_form_comments_mkk9eeyg',
  leadSource: 'status_mkk97xvg',
};

export const MondayColumnTypes = {
  firstName: 'text',
  lastName: 'text',
  phone: 'phone',
  email: 'email',
  groupType: 'select',
  venue: 'select',
  numberOfPeople: 'number',
  eventDate: 'date',
  message: 'text',
  name: 'text',
  leadSource: 'select',
};

export default class MondayStore extends BaseStore {
  @action
  send(items) {
    const fields = Array.isArray(items)
      ? items
      : Object.entries(items).reduce((acc, [key, value]) => {
          if (!Object.keys(MondayColumnNames).includes(key)) return acc;
          if (!value) return acc;

          return [
            ...acc,
            {
              key: MondayColumnNames[key],
              value,
              type: MondayColumnTypes[key],
            },
          ];
        }, []);

    return request({
      method: 'POST',
      path: '/1/forms/monday/',
      body: {
        fields: [
          ...[
            {
              key: MondayColumnNames.leadSource,
              value: 'Contact Form',
              type: MondayColumnTypes.leadSource,
            },
          ],
          ...fields,
        ],
      },
    });
  }
}
